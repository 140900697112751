import {
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react';
import CityIDButton from './CityIDButton';
import CityIDModal from './CityIDModal';
import {SuccessIcon} from './icons/SuccessIcon';
import {KeyboardEvent, ReactNode, useContext} from 'react';
import {SuccessContext} from '@/context/SuccessContext';
import React, {useEffect, useState} from 'react';

type SuccessModalProps = {
  children: ReactNode | undefined;
};

export default function SuccessModal(props: SuccessModalProps) {
  const context = useContext(SuccessContext);
  const disclosure = context.disclosure;
  const [countdown, setCountdown] = useState(5);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    // if (e.key === 'Enter' || e.key === 'Escape') {
    disclosure.onClose();
    // }
  };

  React.useEffect(() => {
    if (disclosure.isOpen) {
      setCountdown(5);
      if (countdown > 0) {
        setInterval(() => {
          setCountdown(prev => prev - 1);
        }, 1000);
      } else if (countdown === 0) {
        disclosure.onClose(); // Close modal when countdown reaches 0
      }
    }
  }, [disclosure.isOpen]);

  React.useEffect(() => {
    if (countdown === 0) {
      disclosure.onClose(); // Close modal when countdown reaches 0
    }
  }, [countdown]);

  const handleTimeUp = () => {
    disclosure.onClose();
    // Call any other function you want here
  };

  return (
    <CityIDModal
      isOpen={disclosure.isOpen}
      onOpenChange={disclosure.onOpenChange}
      onKeyDown={onKeyDown}
    >
      <ModalContent>
        {(onClose: any) => (
          <>
            <ModalHeader className="flex flex-col gap-1 text-center">
              Success
            </ModalHeader>
            <ModalBody>
              <div className="flex justify-center items-center">
                <SuccessIcon />
              </div>
              <div className="text-center mb-4">{props.children}</div>
            </ModalBody>
            <ModalFooter className="flex justify-center items-center">
              <CityIDButton color="primary" onPress={onClose}>
                OK
              </CityIDButton>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </CityIDModal>
  );
}
