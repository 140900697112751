export type ModalDisclosure = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onOpenChange: () => void;
  isControlled: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getButtonProps: (props?: any) => any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getDisclosureProps: (props?: any) => any;
};

export const DEFAULT_MODAL_DISCLOSURE: ModalDisclosure = {
  isOpen: false,
  onOpen: () => {},
  onClose: () => {},
  onOpenChange: () => {},
  isControlled: false,
  getButtonProps: () => {},
  getDisclosureProps: () => {},
};
