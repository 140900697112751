'use client';

import {
  Link,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  User,
} from '@nextui-org/react';
import {LogoIcon} from './icons/LogoIcon';
import {UserIcon} from './icons/UserIcon';
import {useUserSession} from '@/hooks/useUserSession';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faCircleUser} from '@fortawesome/free-regular-svg-icons';

// import * as userSessionRepo from '@/repositories/user-session-repo';

export default function CityIDNavBar() {
  const session = useUserSession();

  return (
    <Navbar maxWidth={'full'} className="header bg-white p-2 ">
      <NavbarBrand>
        <LogoIcon />
        <p className="font-bold text-inherit ml-4">City ID</p>
      </NavbarBrand>

      <NavbarContent justify="end">
        {/* Enable this bit if you want the dark theme switcher back. */}
        {/* <NavbarItem>
          <ThemeSwitcher></ThemeSwitcher>
        </NavbarItem> */}

        {/* Enable this bit for the help icon.  When there's help to be had. */}
        {/* <NavbarItem className="hidden lg:flex">
          <HelpIcon />
        </NavbarItem> */}

        <NavbarItem>
          <User
            name={
              session.user?.userDetails.name ?? session.user?.userDetails.email
            }
            className="bg-transparent"
            description={
              <Link href="/logout" size="sm" className="text-destructive">
                Sign Out
              </Link>
            }
            avatarProps={{
              showFallback: true,
              fallback: (
                <FontAwesomeIcon
                  className="text-secondary text-3xl"
                  icon={faCircleUser}
                />
              ),
            }}
          />
        </NavbarItem>
      </NavbarContent>
    </Navbar>
  );
}
