import {
  DEFAULT_MODAL_DISCLOSURE,
  ModalDisclosure,
} from '@/models/ModalDisclosure';
import {Dispatch, ReactNode, SetStateAction, createContext} from 'react';

export class SuccessContextProps {
  public readonly disclosure: ModalDisclosure;
  private readonly setSuccessMessage: Dispatch<
    SetStateAction<string | ReactNode>
  >;

  constructor(
    disclosure: ModalDisclosure = DEFAULT_MODAL_DISCLOSURE,
    setSuccessMessage: Dispatch<SetStateAction<string | ReactNode>> = () => ''
  ) {
    this.disclosure = disclosure;
    this.setSuccessMessage = setSuccessMessage;
  }

  showSuccess(message: string | ReactNode = '') {
    this.setSuccessMessage(message);
    this.disclosure.onOpen();
  }
}

export const SuccessContext = createContext(new SuccessContextProps());
