import {Modal} from '@nextui-org/react';
import {KeyboardEventHandler, ReactNode} from 'react';

type CityIDModalProps = {
  children: ReactNode | undefined;
  isOpen: boolean;
  onOpenChange: () => void;
  onKeyDown?: KeyboardEventHandler<HTMLElement> | undefined;
};

export default function CityIDModal(
  props: CityIDModalProps = {
    children: undefined,
    isOpen: false,
    onOpenChange: () => {},
    onKeyDown: undefined,
  }
) {
  return (
    <Modal
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
      isDismissable={false}
      backdrop="blur"
      radius="none"
      shadow="md"
      size="lg"
      classNames={{
        closeButton: 'collapse',
      }}
      onKeyDown={props.onKeyDown}
    >
      {props.children}
    </Modal>
  );
}
