import styles from '../styles/loader.module.scss';

type LoaderProps = {
  message?: string;
};

export default function Loader(props: LoaderProps = {message: ''}) {
  console.debug(`Loader message: ${props.message}`);

  return (
    <div className={styles.loaderContainer}>
      <div
        className="fixed inset-0 bg-slate-50 dark:bg-slate-950"
        style={{zIndex: 100}}
      />
      <div className={styles.center}>
        <div className={styles.spinner} />
        <p className={`${styles.loadingMessage} text-black dark:text-white`}>
          Loading
        </p>
        {props.message && (
          <p className={`${styles.message} text-black dark:text-white`}>
            {props.message}
          </p>
        )}
      </div>
    </div>
  );
}
