import {Button, Tooltip} from '@nextui-org/react';

import {ReactNode} from 'react';

type CityIDButtonProps = {
  children?: ReactNode;
  color?:
    | 'primary'
    | 'default'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'danger';
  variant?:
    | 'bordered'
    | 'solid'
    | 'light'
    | 'flat'
    | 'faded'
    | 'shadow'
    | 'ghost';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onPress?: (e: any) => void;
  size?: 'sm' | 'md' | 'lg';
  tooltip?: string;
  isDisabled?: boolean;
  class?: string;
};

export default function CityIDButton(
  props: CityIDButtonProps = {
    children: undefined,
    color: undefined,
    variant: undefined,
    onPress: undefined,
    size: undefined,
    tooltip: undefined,
    isDisabled: undefined,
  }
) {
  const button = (
    <Button
      color={props.color}
      variant={props.variant}
      radius="none"
      className={props.class}
      onPress={props.onPress}
      isDisabled={props.isDisabled}
    >
      {props.children}
    </Button>
  );
  if (props.tooltip) {
    return <Tooltip content={props.tooltip}>{button}</Tooltip>;
  }
  return button;
}
