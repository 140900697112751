import Image from 'next/image';
import React from 'react';

const DEFAULT_SIZE = 54;

export const LogoIcon = () => {
  const alt = 'logo';
  const src = '/logo.svg';
  return (
    <Image alt={alt} src={src} width={DEFAULT_SIZE} height={DEFAULT_SIZE} />
  );
};
