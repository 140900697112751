'use client';

import {UserSession} from '@/repositories/user-session-repo';
import {useRouter} from 'next/navigation';
import * as userSessionRepo from '@/repositories/user-session-repo';
import {useEffect, useState} from 'react';

export const useUserSession = (): {user: UserSession | undefined} => {
  const router = useRouter();
  const [user, setUser] = useState<UserSession>();

  useEffect(() => {
    if (!userSessionRepo.isLoggedIn()) {
      router.push('/login');
    }

    setUser(userSessionRepo.getSession());
  }, [router]);

  return {user};
};
