'use client';
import '../globals.css';

import {Inter} from 'next/font/google';
import {Providers} from '../providers';
import CityIDNavBar from '@/components/CityIDNavbar';
import Loader from '@/components/Loader';
import {ReactNode, useState} from 'react';
import {LoaderContext, LoaderContextProps} from '@/context/LoaderContext';
import {SuccessContext, SuccessContextProps} from '@/context/SuccessContext';
import SuccessModal from '@/components/SuccessModal';
import {useDisclosure} from '@nextui-org/react';
import {Toaster} from 'react-hot-toast';

const inter = Inter({subsets: ['latin']});

export default function MyApp({Component, pageProps}: any) {
  const [loaderIsVisible, setLoaderIsVisible] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState<string | ReactNode>('');
  const successDisclosure = useDisclosure();

  const classes = `${inter.className} w-screen h-screen bg-gray-200 dark:bg-black`;
  return (
    <Providers>
      <SuccessContext.Provider
        value={new SuccessContextProps(successDisclosure, setSuccessMessage)}
      >
        <LoaderContext.Provider
          value={
            new LoaderContextProps(
              loaderIsVisible,
              setLoaderIsVisible,
              setLoaderMessage
            )
          }
        >
          <main className={classes}>
            <CityIDNavBar />
            <Component {...pageProps} />
            <SuccessModal>{successMessage}</SuccessModal>
          </main>
          {loaderIsVisible && <Loader message={loaderMessage} />}
          <Toaster position="bottom-left" reverseOrder={false} />
        </LoaderContext.Provider>
      </SuccessContext.Provider>
    </Providers>
  );
}
